@import "~bootstrap/scss/bootstrap";

.gradient-bkg
{
    background-image: linear-gradient(to right, $legalgreen, $lightgreen);
}

.main-carousel
{
    min-height: 500px !important;
}